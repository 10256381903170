import up from 'unpoly';
import 'unpoly/unpoly-migrate';
import '../compilers';
import Alpine from '../alpinejs';
import './real-100vw';
import './tracking';
import './form-submit-handlers';
import './misc/imbox-open-form-links';

import './views/blocks/animated-banner';
import './views/blocks/countdown';
import './views/blocks/lottie-animation';
import './views/blocks/pardot-form-solutions-2';
import './views/components/applications/application-file-upload-control';
import './views/components/blocks/course-filter';
import './views/components/blocks/newsletter-signup';
import './views/components/blocks/pardot-form';
import './views/components/boxes/box-email-form';
import './views/components/forms/precognitive/form';
import './views/components/layout/expandable';
import './views/components/misc/reveal-button';
import './views/components/templates/applications/application-files-template';
import './views/templates/page-checkout-stripe';
import './views/templates/page-quiz';

// Remove the old berghs_user_consent cookie
document.cookie =
    'berghs_user_consent=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';

up.fragment.config.runScripts = true;
up.history.config.updateMetaTags = true;
up.log.config.format = false;
up.log.enable();

window.Alpine = Alpine;

Alpine.start();

if (!('container' in document.documentElement.style)) {
    import('container-query-polyfill');
}
