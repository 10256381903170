import { compiler } from 'unpoly';

compiler('.js-iframe-auto-height', (iframe) => {
    const updateHeight = () => {
        iframe.style.height = `${iframe.contentWindow.document.documentElement?.scrollHeight}px`;
    };

    const resizeObserver = new ResizeObserver(updateHeight);

    resizeObserver.observe(iframe);

    const onLoad = () => {
        const mutationObserver = new MutationObserver(updateHeight);

        mutationObserver.observe(iframe.contentWindow.document, {
            attributes: true,
            childList: true,
            subtree: true,
        });
    };

    iframe.addEventListener('DOMContentLoaded', updateHeight);
    iframe.addEventListener('load', onLoad);
    iframe.addEventListener('load', updateHeight);
    window.addEventListener('load', updateHeight);
    window.addEventListener('resize', updateHeight);

    updateHeight();

    return () => {
        resizeObserver.disconnect();

        iframe.removeEventListener('DOMContentLoaded', updateHeight);
        iframe.removeEventListener('load', onLoad);
        iframe.removeEventListener('load', updateHeight);
        window.removeEventListener('load', updateHeight);
        window.removeEventListener('resize', updateHeight);
    };
});
